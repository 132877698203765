<template>
  <a-modal title="日志详情" :width="900" :visible="visible" :confirmLoading="confirmLoading" @cancel="handleCancel" :footer="null">
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item label="具体消息" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea :disabled="true"  :rows="4" v-decorator="['message']" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
  export default {
    data() {
      return {
        labelCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 5
          }
        },
        wrapperCol: {
          xs: {
            span: 24
          },
          sm: {
            span: 15
          }
        },
        visible: false,
        confirmLoading: false,
        form: this.$form.createForm(this)
      }
    },
    methods: {
      // 初始化方法
      details(record) {
        this.visible = true
        setTimeout(() => {
          this.form.setFieldsValue({
            message: record.message
          })
        }, 100)
      },
      handleCancel() {
        this.form.resetFields()
        this.visible = false
      }
    }
  }
</script>
